import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyAF88Igm-MbSWaUwX_pbjnIWeuoTn2CQwk",
    authDomain: "my-room-abroad.firebaseapp.com",
    databaseURL: "https://my-room-abroad.firebaseio.com",
    projectId: "my-room-abroad",
    storageBucket: "my-room-abroad.appspot.com",
    // messagingSenderId: "38832995450"
  }
  firebase.initializeApp(config)
  export default firebase
